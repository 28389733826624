import React, {useEffect, useRef} from 'react';
import styles from '../styles/PostTimerSection.module.css';

interface PostTimerSectionProps {
    unlocked: boolean;
}


const PostTimerSection: React.FC<PostTimerSectionProps> = ({unlocked}) => {
    const videoRef = useRef<HTMLVideoElement>(null); // Ref for the video element
    const iosVideoRef = useRef<HTMLVideoElement>(null); // Ref for the video element
    const [videoEnded, setVideoEnded] = React.useState(false);

    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    useEffect(() => {
        if (!isIOS() && unlocked && videoRef.current) {
            videoRef.current.play().catch((error) => {
                console.error('Video playback failed:', error);
            });
        } else if (isIOS() && unlocked && iosVideoRef.current) {
            iosVideoRef.current.play().catch((error) => {
                console.error('Video playback failed:', error);
            });
        }
    }, [unlocked]);

    return (
        <div className={styles.postTimerContainer}>
            <div className={styles.endSection}>
                <div className={styles.videoContainer}>
                    <video
                        ref={videoRef} // Attach the ref to the video element
                        src="/videos/output.webm"
                        className={styles.video}
                        playsInline
                        style={{display: isIOS() ? 'none' : 'block'}}
                        onEnded={() => setVideoEnded(true)} // Set video ended state
                    ></video>
                    <video
                        ref={iosVideoRef} // Attach the ref to the video element
                        src="/videos/dinsey.mov"
                        className={styles.video}
                        playsInline
                        style={{display: isIOS() ? 'block' : 'none'}}
                        onEnded={() => setVideoEnded(true)} // Set video ended state
                    ></video>
                    <div className={styles.arrowContainer} style={{opacity: videoEnded ? 1 : 0}}>
                        <p className={styles.hint}>Scorri per una sorpresina</p>
                    </div>
                </div>
            </div>

            {/* Section 2: Message */}
            {/*<div className={styles.messageSection}>*/}
            {/*    /!*<p className={styles.message}>{message}</p>*!/*/}
            {/*</div>*/}
            {videoEnded && (
                <div className={styles.messageSection}>
                    <div className={styles.subtitle}>AUGURI AMORE MIO!</div>
                    <p className={styles.bodyText}>
                        Finalmente è arrivato il TUO giorno speciale. Dato che sei letteralmente una principessa, come potevo
                        non mettere un intro a questo giorno di questo tipo! (non esagero a dire che se dovessi descriverla una
                        principessa, la descriverei così come sei te, in carattere e aspetto)
                    </p>
                    <p className={styles.bodyText}>
                        Sono arrivati i tuoi 19 anni e nonostante noi ci conosciamo da solo 1 anno sembra che ti conosco da
                        quanto sei nata, sembra che abbiamo già trascorso una vita intera insieme. Assurdo vero? VERO?
                    </p>
                    <p className={styles.bodyText}>
                        La prima volta che ti vidi era a teatro l’anno scorso e, ancora me lo ricordo, quanto mi colpì quel
                        sorriso e quegli occhietti che spruzzavano felicità da ogni poro. Quello stesso sorriso che oggi mi ti
                        fa guardare e dire “cazzo quanto sono fortunato”, quello stesso sorriso che oggi farei di tutto per
                        farti tirare fuori. Da lì ho iniziato a conoscere anche cosa c’era dietro quel sorriso, e ho scoperto
                        la persona dolce e sensibile che sei. In poco tempo che ci parlavamo mi sentivo talmente a mio agio
                        che preferivo aprirmi con te che con altre persone che conoscevo da 5, 10 o 20 anni.
                    </p>
                    <p className={styles.bodyText}>
                        Abbiamo creato un legame profondo in qualche mese. Tu per me c’eri, per una persona che conoscevi appena. All’epoca posso dire che la cosa mi sorprendeva, ma ora che ti conosco in realtà so che è perché sei una persona che ci tiene agli altri, amici e parenti, so che sei una persona che crea dei legami profondi con chi glielo permette. Ti ho vista lottare per risolvere qualsiasi discussione, lottare per quei legami a cui tieni tanto, e questo mi ha fatto capire quanto effettivamente mi dovevo ritenere fortunato ad essere uno di quelli. Ora lo so, e mi sono promesso di non darla mai per scontata questa cosa.
                        Vorrei anche farti capire quanto significhi per me, mi hai fatto ridere e divertire come pochi, mi hai ascoltato come non sono mai stato ascoltato, sei stata li quando ho allontanato tutti dalla mia vita, ma sopratutto mi hai fatto parlare, mi hai fatto capire quanto sia importante aprirsi, e nonostante tu abbia 6 anni in meno di me… mi hai fatto crescere. Quindi inutile che mi chiedono come ho fatto ad innamorarmi di una ragazzina di 18 anni, perché non mi stancherò mai di ripetere che io mi sono innamorato di una donna, una donna che nonostante quello che ha passato ha ancora la forza di sorridere, la donna più fottutamente bella e dolce del mondo.
                        <br/>
                        Ma poi, sei una persona piena di passioni, hai voglia di fare di vivere, ti piace cucinare, ballare, disegnare, ti piace la medicina, ma io dico… come si fa a non innamorarsi di te, cioè io qui ogni giorno mi innamoro sempre di più, tra un po’ si potrebbe fare anche grave la situazione
                    </p>

                    <div className={styles.subtitle}>SPECIAL THANKS TO</div>
                    <p className={styles.bodyText}>
                        Chiudo dicendoti grazie dei bei momenti che abbiamo passato insieme, grazie dei viaggi che abbiamo
                        fatto, grazie delle serate passate a divertirci, ma grazie anche dei momenti di silenzio passati
                        insieme, delle nottate in macchina abbracciati a non fare nulla se non ascoltare i nostri battiti,
                        le serate a guardare le stelle in silenzio...
                    </p>

                    <p className={styles.bodyText}>
                        Ah e… grazie per avermi fatto scoprire che l’amore delle favole esiste anche nella realtà.
                    </p>

                    <div className={styles.subtitle}>WRITTEN AND DIRECTED BY</div>
                    <p className={styles.bodyText}>
                        <b>Leonardo</b>
                    </p>

                    <div className={styles.subtitle}>FINAL NOTE</div>
                    <p className={styles.bodyText}>
                        <b>Ti amo 1000 infinito</b>
                    </p>
                </div>
            )}
        </div>
    );
};


export default PostTimerSection;
