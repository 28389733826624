// src/App.tsx
import React, { useState } from 'react';
import './App.css';
import Countdown from './components/Countdown';
import PostTimerSection from "./components/PostTimerSection";
import styles from "./styles/Countdown.module.css";
import lockImage from "./assets/lock.png";

const App: React.FC = () => {
    const [isTimerEnded, setIsTimerEnded] = useState(false);

    const [unlocked, setUnlocked] = useState(false);
    const handleTimerEnd = () => {
        setIsTimerEnded(true); // Update state when the timer ends
    };

    const handleUnlock = () => {
        if (isTimerEnded) {
            setUnlocked(true);
        }
    }
    return (
        <div className="app">
            <img
                style={{display: unlocked ? 'none' : 'unset'}}
                className={styles.lockImage}
                src={lockImage} alt="Lock"
                onClick={handleUnlock}/>
            {!isTimerEnded ? (
                <Countdown
                    targetDate="2024-11-18" // Replace with the actual target date
                    onTimerEnd={handleTimerEnd}
                />
            ) : (
                <PostTimerSection unlocked={unlocked}/>
            )}
        </div>
    );
};

export default App;
