// src/components/Countdown.tsx
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import styles from '../styles/Countdown.module.css';
import lockImage from '../assets/lock.png';

interface CountdownProps {
  targetDate: string; // Format: 'YYYY-MM-DD'
  onTimerEnd: () => void; // Callback to notify when the timer ends
}

const Countdown: React.FC<CountdownProps> = ({ targetDate, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  //TODO: RE-ENABLE

  const [timerEnded, setTimerEnded] = useState(false);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = moment().tz('Europe/Rome'); // Current time in Rome
      const target = moment.tz(`${targetDate} 00:00:00`, 'Europe/Rome'); // Target date at midnight in Rome
      // const target = moment.tz(`2024-11-17 16:12:31`, 'Europe/Rome'); // Target date at midnight in Rome
      const difference = target.diff(now); // Difference in milliseconds
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(moment.duration(difference).asDays()),
          hours: moment.duration(difference).hours(),
          minutes: moment.duration(difference).minutes(),
          seconds: moment.duration(difference).seconds(),
        });
      } else {
        setTimerEnded(true);
        onTimerEnd(); // Notify parent that the timer has ended
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer); // Clean up the interval
  }, [targetDate, onTimerEnd]);


  return (
      <div className={styles.countdown} style={{opacity: timerEnded ? 0 : 1}}>
        <div className={styles.time}>
          <span>{timeLeft.days} days</span>
          <span>{timeLeft.hours} hours</span>
          <span>{timeLeft.minutes} minutes</span>
          <span>{timeLeft.seconds} seconds</span>
        </div>
      </div>
  );
};

export default Countdown;
